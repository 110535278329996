function Viewport(breakpoints) {

    // Get the breakpoint config
    this.breakpoints = breakpoints;

    // Constuct the breakpoint for the media query
    this.mediaQuery = function(breakpoint) {
        return '(' + this.breakpoints[breakpoint].type + ': ' + this.breakpoints[breakpoint].size + 'px)';
    }

    // Check if the media query matches
    this.matchMedia = function(breakpoint) {
        return window.matchMedia(this.mediaQuery(breakpoint));
    }

    // Check if the media query matches
    this.matches = function(breakpoint) {
        return window.matchMedia(this.mediaQuery(breakpoint)).matches;
    }

    // Construct the custom media query
    this.customMediaQuery = function(breakpoint) {
        return breakpoint;
    }

    // Check if the custom media query matches
    this.customMatchMedia = function(breakpoint) {
        return window.matchMedia(this.customMediaQuery(breakpoint));
    }

    // Check if the custom media query matches
    this.customMatches = function(breakpoint) {
        return window.matchMedia(this.customMediaQuery(breakpoint)).matches;
    }

    // Watch for viewport resize
    this.resize = function(callback) {
        var windowResizing;

        window.addEventListener('resize', function() {
            clearTimeout(windowResizing);
            windowResizing = setTimeout(function() {
                if (typeof callback == 'function') {
                    callback();
                }
            }, 100);
        });
    }
}

var preventScroll = {
    active: false,
    on: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;

        // Determine the `scrollTop` to use. Some browsers require checking the
        // `body`, others use `html`.
        var bodyScrollTop = body.scrollTop;
        var htmlScrollTop = htmlEl.scrollTop;
        var scrollTop = bodyScrollTop ? bodyScrollTop : htmlScrollTop;

        // Store the current value of the htmlEl's styles – we're about to override
        // them.
        this.currentPosition = htmlEl.style.position;
        this.currentOverflow = htmlEl.style.overflowY;
        this.currentWidth = htmlEl.style.width;

        // Fixing the position of the `htmlEl` prevents the page from scrolling
        // at all.
        htmlEl.style.position = 'fixed';
        // Setting `overflowY` to `scroll` ensures that any scrollbars that are
        // around stick around. Otherwise, there would be a "jump" when the page
        // becomes unscrollable as the bar would vanish.
        htmlEl.style.overflowY = 'scroll';
        // This makes sure that the page doesn't collapse (usually your CSS will
        // prevent this, but it's best to be safe)
        htmlEl.style.width = '100%';
        // Scoot down the `htmlEl` to be in the same place that the user had
        // scrolled to.
        htmlEl.style.top = (0 - scrollTop) + 'px';

        // Set active state
        this.active = true;
    },

    off: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;

        // Reset `htmlEl` to the original styles.
        htmlEl.style.position = this.currentPosition;
        htmlEl.style.overflowY = this.currentOverflow;
        htmlEl.style.width = this.currentWidth;

        // Retrieve our original scrollTop from the htmlEl's top

        htmlEl.classList.add('no-smooth-scroll');

        var scrollTop = -parseInt(htmlEl.style.top);
        // Return us to the original scroll position. Once again, we set this on
        // both the `body` and the `htmlEl` to be safe.
        htmlEl.scrollTop = scrollTop;
        body.scrollTop = scrollTop;

        htmlEl.classList.remove('no-smooth-scroll');

        // Unset active state
        this.active = false;
    }
}


function Header() {
    
    let viewport = new Viewport(AndrewMartin.breakpoints);

    this.outerWrapper = document.querySelector('.outer-wrapper');
    this.notice = document.querySelector('.js-notice');
    this.headerMarker = document.querySelector('.js-header-marker');
    this.header = document.querySelector('.js-header');
    this.headerTop = document.querySelector('.js-header__top');
    this.headerNavContainer = document.querySelector('.js-header__nav');
    this.headerBackground = document.querySelector('.js-header-background');
    this.headerNav = document.querySelector('.js-header-nav');
    this.headerNavList = document.querySelector('.js-nav-list');
    this.headerNavItems = document.querySelector('.js-header-nav__items');
    this.headerNavItem = document.querySelectorAll('.js-header-nav__item');
    this.headerNavButton = document.querySelectorAll('.js-header-nav__button');
    this.headerNavToggle = document.querySelector('.js-header-nav__toggle');

    this.heights = {};

    this.init = () => {
        if (!this.header) {
            return;
        }
        this.watchNavClick();
        this.watchBackgroundClick();
        this.watchPageScroll();
        this.watchNavToggle();
        this.watchPageResize();
        this.addClearHeader();
    }

    // Watch the navigation for click events
    this.watchNavClick = () => {
        this.headerNavButton.forEach((headerNavButton) => {
            let item = headerNavButton.parentNode;

            headerNavButton.addEventListener('click', (e) => {
                e.preventDefault();
                if (e.target.parentNode.dataset.url) {
                    window.open(e.target.parentNode.dataset.url, '_self');
                    return;
                };
                if (viewport.matches('nav')) {
                    if (this.isNavItemActive(item)) {
                        this.deactivateNavItem(true);
                    } else {
                        this.activateNavItem(item, true);
                    }
                } else {
                    if (this.activeNavItem()) {
                        if (this.isNavItemActive(item)) {
                            this.deactivateNavItem();
                            this.deactivateNav();
                        } else {
                            this.deactivateNavItem();
                            this.activateNavItem(item);
                            this.activateNav();
                        }
                    } else {
                        this.activateNavItem(item);
                        this.activateNav();
                    }
                }
            });
        });
    }

    // Watch the background for click events
    this.watchBackgroundClick = () => {
        this.headerBackground.addEventListener('click', this.deactivateNav.bind(this));
    }

    // Watch the page for scroll events
    this.watchPageScroll = () => {
        this.checkPageScroll();
        document.addEventListener('scroll', this.checkPageScroll.bind(this));
    }

    // Watch the navigation toggle for click events
    this.watchNavToggle = () => {
        this.headerNavToggle.addEventListener('click', this.toggleNav.bind(this));
    }

    // Watch the page for resize events
    this.watchPageResize = () => {
        viewport.resize(() => {
            if (viewport.matches('nav')) {
                this.setNavheight();
            } else {
                this.resetNavheight();
            }
        })

        viewport.matchMedia('nav').addListener((e) => {
            if (!e.matches) {
                if (this.isNavActive()) {
                    this.deactivateNav(true);
                }
            }
        });
    }

    // Check how far the page has scrolled to determine whether or not to show the clear header (desktop only)
    this.checkPageScroll = () => {

        if (!this.header.classList.contains('header--fixed')) {
            if (this.header.clientHeight != 0) {
                this.heights.header = this.header.clientHeight;
            }
            if (this.headerTop.clientHeight != 0) {
                this.heights.headerTop = this.headerTop.clientHeight;
            }
            if (this.headerNavContainer.clientHeight != 0) {
                this.heights.headerNavContainer = this.headerNavContainer.clientHeight;
            }
        }

        if (this.atTop()) {
            this.deactivateFixed();
        } else {
            this.activateFixed();
        }
    }

    // Is the page scrolled to the top
    this.atTop = () => {
        var offset = parseInt(window.getComputedStyle(this.outerWrapper, null).getPropertyValue('padding-top'));
        if (viewport.matches('nav')) {
            return this.headerMarker.getBoundingClientRect().top >= offset;
        } else {
            if (this.headerMarker.clientHeight == this.heights.headerNavContainer) {
                return this.headerMarker.getBoundingClientRect().top >= offset;
            } else {
                return this.headerMarker.getBoundingClientRect().top + this.heights.headerTop >= offset;
            }
        }
    }

    // Activate the fixed header
    this.activateFixed = () => {
        this.header.classList.add('header--fixed');
        this.headerNavList.classList.add('header-nav__list--fixed');

        this.removeClearHeader();
    }

    // Deactivate the fixed header
    this.deactivateFixed = () => {
        this.header.classList.remove('header--fixed');
        this.headerNavList.classList.remove('header-nav__list--fixed');
        this.headerMarker.classList.remove('header-marker__height--active');
        this.addClearHeader();

        if (!viewport.matches('nav')) {
            this.headerMarker.style.top = this.heights.headerTop + 'px';
            this.headerMarker.style.height = this.heights.headerNavContainer + 'px';
        } else {
            this.headerMarker.style.top = 0;
            this.headerMarker.style.height = this.heights.headerTop + 'px';
        }
    }

    this.addClearHeader = function() {
        if (!this.header.classList.contains('header--plain')) {
            if (window.pageYOffset == 0 && !document.querySelector('.header--clear')) {
                this.header.classList.add('header--clear');
            }
        }
    }

     // Remove the 'clear' styles from the header
     this.removeClearHeader = function() {
        if (!this.header.classList.contains('header--plain')) {
            this.header.classList.remove('header--clear');
        }
    }

    // Is the nav active?
    this.isNavActive = () => {
        return this.headerNav.classList.contains('header-nav--active-a');
    }

    // Is the nav item active?
    this.isNavItemActive = (el) => {
        return el.classList.contains('header-nav__item--active-a');
    }

    // Toggle the nav
    this.toggleNav = () => {
        if (this.isNavActive()) {
            this.deactivateNav();
        } else {
            if (AndrewMartin.search.isSearchActive()) {
                AndrewMartin.search.deactivateSearch();
            }
            this.activateNav();
        }
    }

    // Activate the nav
    this.activateNav = () => {
        this.navActivation(() => {
            if (viewport.matches('nav')) {
                this.headerNavToggle.setAttribute('aria-expanded', true);
                this.setNavheight();
                if (!preventScroll.active) {
                    preventScroll.on();
                }
            }
        });
    }

    // Deactivate the nav
    this.deactivateNav = (resizedFromNav) => {
        this.navDeactivation(() => {
            if (viewport.matches('nav') || resizedFromNav) {
                this.headerNavToggle.setAttribute('aria-expanded', false);
                this.resetNavheight();
                this.deactivateNavItem(true);
                preventScroll.off();
            }
        });
    }

    // Set the bottom of the nav and background to site flush with the bottom bar
    this.setNavheight = () => {
        this.headerNavItems.style.height = window.innerHeight + 'px';
    }

    // Reset the bottom styles of the nav and background
    this.resetNavheight = () => {
        this.headerNavItems.style.height = null;
    }

    // Get the active nav item
    this.activeNavItem = () => {
        return document.querySelector('.header-nav__item--active-a');
    }

    // Activate the nav item
    this.activateNavItem = (el, mobile) => {
        this.navItemActivation(el, () => {
            if (mobile) {
                this.headerNavItems.dataset.navItem = el.dataset.navItem;
                // this.headerNavItems.scrollTop = 0;
            }
        });

        let button = el.querySelector('.js-header-nav__button');

        if (button) {
            button.setAttribute('aria-expanded', false);
        }
    }

    // Deactivate the nav item
    this.deactivateNavItem = (mobile) => {
        let el = this.activeNavItem();

        if (el) {
            this.navItemDeactivation(el, () => {
                if (mobile) {
                    this.headerNavItems.removeAttribute('data-nav-item');
                }
            });

            let button = el.querySelector('.js-header-nav__button');

            if (button) {
                button.setAttribute('aria-expanded', true);
            }
        }
    }

    this.navItemActivation = (el, callback) => {
        this.header.classList.add('header__nav-item--active');
        el.classList.add('header-nav__item--active-a');
        if (typeof callback === 'function') {
            callback();
        }

        let timeout = {
            small: [1, 1, 300],
            large: [0, 0, 0]
        }

        timeout = viewport.matches('nav') ? timeout.small : timeout.large;

        setTimeout(() => {
            setTimeout(() => {
                el.classList.add('header-nav__item--active-b');
                setTimeout(() => {
                    if (el.dataset.navIsLink) {
                        this.headerNav.setAttribute('data-is-single', true);
                    } else {
                        this.headerNav.removeAttribute('data-is-single');
                    }
                    el.classList.add('header-nav__item--active-c');
                }, timeout[2]);
            }, timeout[1]);
        }, timeout[0]);
    }

    this.navItemDeactivation = (el, callback) => {
        this.header.classList.remove('header__nav-item--active');
        el.classList.remove('header-nav__item--active-c');

        let timeout = {
            small: [1, 300, 1],
            large: [0, 0, 0]
        }

        timeout = viewport.matches('nav') ? timeout.small : timeout.large;

        setTimeout(() => {
            el.classList.remove('header-nav__item--active-b');
            setTimeout(() => {
                setTimeout(() => {
                    if (typeof callback === 'function') {
                        callback();
                    }
                    el.classList.remove('header-nav__item--active-a');
                }, timeout[2]);
            }, timeout[1]);
        }, timeout[0]);
    }

    this.navActivation = (callback) => {
        if (viewport.matches('nav')) {
            this.calculateNavToggleTop();
            this.positionNavToggle();
        }
        this.headerNav.classList.add('header-nav--active-a');
        if (typeof callback === 'function') {
            callback();
        }

        let timeout = {
            small: [1, 1, 300],
            large: [0, 0, 0]
        }

        timeout = viewport.matches('nav') ? timeout.small : timeout.large;

        setTimeout(() => {

            setTimeout(() => {
                this.headerNav.classList.add('header-nav--active-b');

                setTimeout(() => {
                    if (viewport.matches('nav')) {
                        this.headerNavToggle.style.top = null;
                    }
                    this.headerNav.classList.add('header-nav--active-c');

                    // Set 'a' again - this is needed if moving from one item to another directly
                    this.headerNav.classList.add('header-nav--active-a');
                }, timeout[2]);
            }, timeout[1]);
        }, timeout[0]);
    }

    this.navDeactivation = (callback) => {
        if (viewport.matches('nav')) {
            this.positionNavToggle();
        }
        this.headerNav.classList.remove('header-nav--active-c');

        let timeout = {
            small: [1, 300, 300],
            large: [0, 0, 0]
        }

        timeout = viewport.matches('nav') ? timeout.small : timeout.large;

        setTimeout(() => {
            this.headerNav.classList.remove('header-nav--active-b');
            setTimeout(() => {
                setTimeout(() => {
                    if (viewport.matches('nav')) {
                        this.headerNavToggle.style.top = null;
                    }
                    this.headerNav.classList.remove('header-nav--active-a');
                    if (typeof callback === 'function') {
                        callback();
                    }
                }, timeout[2]);
            }, timeout[1]);
        }, timeout[0]);
    }

    this.calculateNavToggleTop = () => {
        let toggleBounds = this.headerNavToggle.getBoundingClientRect();
        this.toggleTop = (toggleBounds.top + (toggleBounds.height / 2)) + 'px';
    }

    this.positionNavToggle = () => {
        this.headerNavToggle.style.top = this.toggleTop;
    }
}

AndrewMartin.header = new Header();

AndrewMartin.header.init();
